import { IConnection } from '@nestia/fetcher';

import api from '@flounder/backend-sdk';
import { headerKey } from '@flounder/captcha-utils';
import {
  TGoogleLogin,
  TGoogleLoginResponse,
  ISignUpWithCaptcha,
  IUpsertUser,
} from '@flounder/contracts';
import { createBackendSDKConnectionParamsForWeb } from '@flounder/shared-apis';

import { extractAppErrorFromBackendSdkResponse } from '../Errors/extract-app-error-from-backend-sdk-response';

export class AuthApi {
  baseUrl = '/api';
  private readonly backendSKDConnectionParams: IConnection;

  constructor() {
    this.backendSKDConnectionParams = createBackendSDKConnectionParamsForWeb();
  }

  async signUp(data: ISignUpWithCaptcha): Promise<IUpsertUser> {
    const { gReCaptchaToken } = data;
    try {
      return await api.functional.api.registration.registerUser(
        {
          ...this.backendSKDConnectionParams,
          headers: { ...this.backendSKDConnectionParams.headers, [headerKey]: gReCaptchaToken },
        },
        data,
      );
    } catch (e) {
      throw extractAppErrorFromBackendSdkResponse(e);
    }
  }

  async googleLogin(data: TGoogleLogin): Promise<TGoogleLoginResponse> {
    try {
      return await api.functional.api.external_login.google.googleLogin(
        this.backendSKDConnectionParams,
        data,
      );
    } catch (e) {
      throw extractAppErrorFromBackendSdkResponse(e);
    }
  }
}
