import { forwardRef } from 'react';
import { Checkbox as TacoCheckbox } from '@elpassion/taco';
import { CheckboxProps as TacoCheckBoxProps } from '@elpassion/taco';

export type CheckBoxProps = TacoCheckBoxProps;

export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  (props, ref) => {
    const { size = 'sm', ...rest } = props;

    return <TacoCheckbox ref={ref} size={size} {...rest} />;
  }
);
