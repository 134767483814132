import { ZodIssue } from 'nestjs-zod/z';

import {
  ConflictError,
  DataValidationErrors,
  isConflictBackendSdkResponseError,
  isFormValidationSdkResponseError,
  isOneErrorMessageSdkResponseError,
  OneMessageError,
} from '@flounder/contracts';

export const extractAppErrorFromBackendSdkResponse = (error: unknown): Error => {
  console.log('error from be sdk', error);
  if (isFormValidationSdkResponseError(error)) {
    const errorData = error.toJSON<{
      message: ZodIssue[];
    }>();

    return new DataValidationErrors(
      errorData.message.message.map(issue => ({
        ...issue,
        message: issue.message,
      })),
    );
  }

  if (isConflictBackendSdkResponseError(error)) {
    return new ConflictError(error.message);
  }

  if (isOneErrorMessageSdkResponseError(error)) {
    return new OneMessageError(error.message);
  }

  return error as Error;
};
