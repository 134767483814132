import { t } from '@lingui/macro';
import { toast, ToastOptions } from 'react-hot-toast';
import { ErrorToast, InfoToast, SuccessToast, WarningToast, TToastProps } from '@flounder/ui';

type IToastProps = {
  options?: Pick<ToastOptions, 'duration' | 'position'>;
} & Partial<TToastProps>;

export const errorToast = (props?: IToastProps) => {
  const { title, options, ...rest } = {
    title: t({ id: 'Error' }),
    ...props,
  };

  return toast.custom(
    t => <ErrorToast title={title} onClose={() => toast.dismiss(t.id)} {...rest} />,
    options,
  );
};

export const successToast = (props?: IToastProps) => {
  const { title, options, ...rest } = {
    title: t({ id: 'Success' }),
    ...props,
  };

  return toast.custom(
    t => <SuccessToast title={title} onClose={() => toast.dismiss(t.id)} {...rest} />,
    options,
  );
};

export const infoToast = (props?: IToastProps) => {
  const { title, options, ...rest } = {
    title: t({ id: 'Info' }),
    ...props,
  };

  return toast.custom(
    t => <InfoToast title={title} onClose={() => toast.dismiss(t.id)} {...rest} />,
    options,
  );
};

export const warningToast = (props?: IToastProps) => {
  const { title, options, ...rest } = {
    title: t({ id: 'Warning' }),
    ...props,
  };

  return toast.custom(
    t => <WarningToast title={title} onClose={() => toast.dismiss(t.id)} {...rest} />,
    options,
  );
};
