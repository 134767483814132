import { t } from '@lingui/macro';
import { useAuth } from '@flounder/cognito-auth/AuthContext';
import { errorToast, successToast } from '../../Toast';

type UseSocialLoginProps = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useSocialLogin = (
  { onError, onSuccess }: UseSocialLoginProps = {
    onSuccess: () => {
      successToast({
        title: t`Successfully logged in`,
      });
    },
    onError: () => {
      errorToast({
        title: t`Social login failed`,
      });
    },
  },
) => {
  const { cognitoApi } = useAuth();

  const mutate = async (email: string, token: string) => {
    try {
      const user = await cognitoApi?.signInWithoutPassword(email);

      if (!user) {
        onError?.();
        return;
      }

      await cognitoApi?.sendCustomChallengeAnswer(user, token);
    } catch (error) {
      console.error(error);
      onError?.();
      return;
    }

    onSuccess?.();
  };

  return {
    mutate,
  };
};
