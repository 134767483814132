import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { t, Trans } from '@lingui/macro';
import { nestJsZodResolver } from 'nestjs-zod-hookform-resolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useConfirmUser, useSignIn } from '@flounder/cognito-auth/Auth.hooks';
import { ConfirmSignUpDto, confirmSignUpSchema, TSignIn } from '@flounder/contracts';
import { ErrorMessage as Error, Link } from '@flounder/ui';
import { routes } from '../../../utils';
import { getMaskedEmail } from '../../../utils/customAuth';
import { getCognitoTranslatedErrorText } from '../../../utils/translateUtils';
import { Column, Field, Form, InputContainer, Submit } from '../../Form';
import { InputCodeField } from '../../Inputs';

interface IConfirmSignUpFormProps {
  newRegisteredUser: TSignIn;
  error?: { message?: string };
}

export const ConfirmSignUpForm = ({ newRegisteredUser, error }: IConfirmSignUpFormProps) => {
  const [, setProcessing] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [notConfirmedError, setNotConfirmedError] = useState<
    { message?: string | undefined } | undefined
  >(error);
  const router = useRouter();

  const { mail } = newRegisteredUser;

  const { confirmUser, resendConfirmationCode } = useConfirmUser();
  const { signIn } = useSignIn();

  const maskedEmail = getMaskedEmail(mail);

  const methods = useForm<ConfirmSignUpDto & { global: string }>({
    resolver: nestJsZodResolver(confirmSignUpSchema),
    defaultValues: {
      code: '',
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;

  const onSubmit: SubmitHandler<ConfirmSignUpDto> = async data => {
    try {
      setProcessing(true);
      await confirmUser(mail, data.code);
      await signIn(newRegisteredUser);
      router.push(routes.home);
    } catch (error) {
      const errorText = getCognitoTranslatedErrorText(error);
      setError('global', { message: errorText });
    } finally {
      setProcessing(false);
      setNotConfirmedError(undefined);
    }
  };

  const resendCode = async () => {
    try {
      await resendConfirmationCode(mail);
      setIsCodeSent(true);
    } catch (error) {
      const errorText = getCognitoTranslatedErrorText(error);
      setError('global', { message: errorText });
    }
  };

  return (
    <div className="w-full">
      <Form
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        globalError={errors.global}
        submitButton={<Submit className="mt-4" text={t`Confirm Account`} isFluid />}
      >
        <p className="text-sm mb-4">
          <Trans>
            We have sent a code by email to {maskedEmail}. Enter it below to confirm your account.
          </Trans>
        </p>
        <Field name="code">
          <Column>
            <InputContainer>
              <InputCodeField id="input-code" label={t({ id: 'Verification Code' })} />
            </InputContainer>
          </Column>
        </Field>
      </Form>
      <Error error={notConfirmedError} />
      <div className="text-sm text-center mt-4">
        {isCodeSent ? (
          <Trans>Code sent! or you can again:</Trans>
        ) : (
          <Trans>Didn't receive a code?</Trans>
        )}
        &nbsp;
        <Link onClick={resendCode}>
          <Trans>Send a new code</Trans>
        </Link>
      </div>
    </div>
  );
};
