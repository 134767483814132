import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import {
  TGoogleLogin,
  TGoogleLoginResponse,
  ISignUpWithCaptcha,
  IUpsertUser,
} from '@flounder/contracts';

import { AuthApi } from './AuthApi';

export const userAuthApi = () => {
  const authApi = new AuthApi();

  const useRegisterAccount = ({
    onSuccess,
    onError,
  }: UseMutationOptions<IUpsertUser, Error, ISignUpWithCaptcha> = {}) =>
    useMutation<IUpsertUser, Error, ISignUpWithCaptcha>({
      mutationFn: data => authApi.signUp(data),
      onSuccess,
      onError,
    });

  const useGoogleLogin = ({
    onSuccess,
    onError,
  }: UseMutationOptions<TGoogleLoginResponse, Error, TGoogleLogin> = {}) =>
    useMutation<TGoogleLoginResponse, Error, TGoogleLogin>({
      mutationFn: data => authApi.googleLogin(data),
      onSuccess,
      onError,
    });

  return {
    useRegisterAccount,
    useGoogleLogin,
  };
};
