import { useSignIn } from '@flounder/cognito-auth/Auth.hooks';
import { CognitoAppErrorMessages } from '@flounder/cognito-auth/Auth.interface';
import { signInSchema, TSignIn } from '@flounder/contracts';
import { CheckBox, Divider, Link } from '@flounder/ui';
import { t, Trans } from '@lingui/macro';
import { nestJsZodResolver } from 'nestjs-zod-hookform-resolver';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { routes } from '../../../utils';
import { getCognitoTranslatedErrorText } from '../../../utils/translateUtils';
import { Column, Field, Form, InputContainer, Submit } from '../../Form';
import { InputField } from '../../Inputs';
import { GoogleButton } from '../components/GoogleButton';
import { ConfirmSignUpForm } from '../ConfirmSignUpForm';

export const CustomSignInForm = () => {
  const [newRegisteredUser, setNewRegisteredUser] = useState<TSignIn>();
  const [notConfirmedErrorText, setNotConfirmedErrorText] = useState<string>();
  const { signIn } = useSignIn();

  const router = useRouter();

  const redirectUrl = (
    router.query?.redirect ? `/${router.query.redirect}` : routes.home
  ) as string;

  const methods = useForm<TSignIn & { global: string }>({
    resolver: nestJsZodResolver(signInSchema),
    defaultValues: {
      mail: '',
      password: '',
    },
  });
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;

  const handleSignIn = async (data: TSignIn) => {
    try {
      const credentials = { mail: data.mail, password: data.password };
      await signIn(credentials);
      await router.push(redirectUrl);
    } catch (error) {
      const errorText = getCognitoTranslatedErrorText(error);
      if (errorText === CognitoAppErrorMessages.UserNotConfirmed) {
        setNewRegisteredUser(data);
        setNotConfirmedErrorText(errorText);
      }
      setError('global', {
        message: errorText,
      });
    }
  };

  const onSubmit: SubmitHandler<TSignIn> = async data => await handleSignIn(data);

  return newRegisteredUser ? (
    <ConfirmSignUpForm
      newRegisteredUser={newRegisteredUser}
      error={{ message: notConfirmedErrorText }}
    />
  ) : (
    <div className="w-full">
      <Form
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        globalError={errors.global}
        submitButton={<Submit text={t`Sign In`} isFluid />}
        className="mb-4"
      >
        <Field name="mail">
          <Column>
            <InputContainer>
              <InputField
                placeholder={t({ id: 'Enter your email' })}
                type="email"
                label={t({ id: 'E-mail' })}
              />
            </InputContainer>
          </Column>
        </Field>

        <Field name="password">
          <Column>
            <InputContainer>
              {/*TODO Input doesnt take suffix icon as a prop for now*/}
              <InputField
                placeholder={t({ id: 'Enter your password' })}
                type="password"
                label={t({ id: 'Password' })}
              />
            </InputContainer>
          </Column>
        </Field>

        <div className="flex items-center justify-between mt-2 mb-10">
          {/*TODO Implement remember me functionality*/}
          <CheckBox disabled name="remember-me" labelText={t({ id: 'Remember me' })} />

          <Link href={routes.forgotPassword}>
            <Trans>Forgot your password?</Trans>
          </Link>
        </div>
      </Form>

      <Divider labelText={t({ id: 'OR' })} className="mb-4" />

      <GoogleButton />
      {/* <FacebookButton fullWidth text={t`Sign in with Facebook`} /> */}

      <p className="text-sm mt-6 text-neutral-400 text-center">
        <Trans>Don’t have an account?</Trans>
        <Link href={routes.signUp} className="font-medium ml-2">
          <Trans>Sign Up</Trans>
        </Link>
      </p>
    </div>
  );
};
