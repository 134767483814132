import { GetServerSideProps } from 'next';
import { withSession } from '@flounder/cognito-auth/withSession';
import { CustomSignIn } from '../../modules/Pages';
import { HttpClient } from '@flounder/http-client';
import { getEnvVariables } from '@flounder/next-utils';

export default CustomSignIn;

export const getServerSideProps: GetServerSideProps = withSession(
  options => new HttpClient({ url: getEnvVariables().API_URL, ...options }),
)();
