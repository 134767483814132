import React from 'react';
import { Divider as TacoDivider } from '@elpassion/taco';
import { DividerProps } from './Divider.types';

export const Divider: React.FC<DividerProps> = ({
  type = 'horizontal',
  ...rest
}) => {
  return <TacoDivider type={type} {...rest} />;
};
