import React from 'react';
import type { NextPage } from 'next';
import { Trans } from '@lingui/macro';
import { AuthLayout } from '../../CustomAuth/components';
import { CustomSignInForm } from '../../CustomAuth/CustomSignInForm';

export const CustomSignIn: NextPage = () => {
  return (
    <AuthLayout>
      <h2 className="text-3xl font-medium mb-2 text-neutral-800 text-center 2xl:text-left">
        <Trans>Login to your account</Trans>
      </h2>
      <p className="text-base text-neutral-500 mb-10 text-center 2xl:text-left">
        <Trans>Welcome back! Please enter your details.</Trans>
      </p>

      <CustomSignInForm />
    </AuthLayout>
  );
};
